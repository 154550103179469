











































































































































































import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSaleReports from "@/components/sidebar-sale-reports.vue";
import ReportService from "@/services/reports-service";
const moment = require('moment')
const VueQrcode = require('@chenfengyuan/vue-qrcode');

@Component({
  components: {
    Header,
    SidebarSaleReports,
    VueQrcode
  },
})
export default class Sales extends Vue {
  public filter = null;
  public filterOn = [];
  trans = [];
  public value = '2';
  isdetailed = false;
  show_cash = false;
  show_credit = false;
  public currentItem = null;
  public currentIndex = -1;
  public currentPage = 1;
  public title = "";
  public options_project = ['All',
    'Aramco-Riyadh',  
		'Aramco-YANBU', 
		'Aramco-JUAYMAH', 
		'Aramco-Jeddah', 'Yasref', 'Marafiq', 'KJO', 'Bahria', 'Grain-Sailos', 'Tanajeeb', 'Milling-company', 
    'Air-fueling-Makkah',
		'Air-fueling-Riyadh',
		'Air-fueling-Shirqiya',
    'Air-fueling-Aseer',
    'Tameed', 'GEMS'];
  public project = this.options_project[0];
  options_salesaccount = [];
  selected_salesaccount = "";
  selected_report_date_from:any = ''; 
  selected_report_date_to :any  = '';
  options_product = [];
  selected_product = "";

  options_party = [];
  selected_party = "";

  options_branch = [];
  selected_branch = "";

  options_division = [];
  selected_division = "";
  public fields = [
    {
      key: "invoice_id",
      label: "Credit #",
    },
    {
      key: "project",
    },
    {
      key: "customer",
    },
    // {
    //   key: "bank",
    // },
    {
        label: "Reference #", 
      key: "ref_invoice",
    },
    {
      key: "po",
      label: "PO #"
    },
    {
      key: "contract_no",
      label: "Contract #"
    },
    {
      key: "total_amount",
    },
    {
      key: "vat_amount",
    },
     {
      key: "retention_amount",
    },
     {
      key: "net_amount",
    },
    {
      key: "created_at",
      label : "Date",
      formatter: (value: any) => {
              return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    {
      key: "created_at",
      label : "Date",
      formatter: (value: any) => {
              return moment(String(value)).format('MM/DD/YYYY')
      }
    },
    {
      key: "Get_invoice"

    },
  ];
  public columns = [
          {
             label: 'credit #',
            field: 'invoice_id',
          },
          {
             label: 'project',
            field: 'project',
          },
          {
             label: 'customer',
            field: 'customer',
          },     
            {
          label: "reference", 
          key: "ref_invoice",
        },
          {
             label: 'po',
            field: 'po',
          },
          {
             label: 'contract_no',
            field: 'contract_no',
          },
          
          {
             label: 'total_amount',
            field: 'total_amount',
          },
          {
             label: 'vat_amount',
            field: 'vat_amount',
          },
           {
             label: 'retention_amount',
            field: 'retention_amount',
          },
          {
             label: 'net_amount',
            field: 'net_amount',
          },
         {
            label: 'date',
            field: 'created_at'
          }
  ];
           
  public items_fields = [
    { key: "item" },
    { key: "price" },
    { key: "quantity" },
    { key: "amount" },
  
  ];
  get rows() {
    return this.selected_items.length;
  }
  public totalamount = 0;
  public net_amount = 0;
  public gross_amount = 0;
  public vat_amount = 0;
  public retention_amount =0;

  public itemid = "";
  public invoice:any = {};
  public sales:any = {};
  public items = [];
  public selected_items = [
    {
      customer: "",
      project: "",
      bank: "",
      po: "",
      vendor: "",
      costcenter: "",
      contract_no: "",
      contractor_no: "",
      invoice_type: "",
      created_at: "",
    },
  ];
  public selected_items_master = [
    {
      customer: "",
      project: "",
      bank: "",
      po: "",
      vendor: "",
      costcenter: "",
      contract_no: "",
      contractor_no: "",
      invoice_type: "",
      created_at: "",
    },
  ];

  // changedValue(data:any) {
  //   if(data == 'All'){
  //     this.selected_items = this.selected_items_master  
  //   }else{
  //   let t =  this.selected_items_master.filter(x=> x.project == data)
  //   this.selected_items = t;
  //   }
  // }
clear(){
  this.selected_report_date_from ='';
  this.selected_report_date_to ='';
  this.project = this.options_project[0];
    this.selected_items_master = []
    this.selected_items = []
}
submit(){
  let obj = {
    start_date : this.selected_report_date_from,
    end_date : this.selected_report_date_to,
    project : this.project,
  }
  this.callservice(obj)
}
callservice(obj:any){
  ReportService.getallcreditreports(obj)
      .then((response) => {
        this.selected_items_master = response.data.sales;
        this.selected_items = response.data.sales;
    
      })
      .catch((e) => {
        console.log(e);
      });  
}

  onRowClicked(item: any, index: any, event: any) {
    ReportService.getsalereport(item.id)
      .then((response) => {
        let total = 0;
        this.totalamount = 0;
        this.invoice = response.data;
        this.value = (item.qrcode)
        
        this.sales = response.data.sales[0];
        response.data.items.forEach( (x :any) => {
             total += Number(x.amount);
        });
        this.totalamount = total
        this.vat_amount = this.totalamount *0.15;
        this.gross_amount = this.vat_amount  +  this.totalamount;
        this.retention_amount = this.totalamount * 0.10;
        this.net_amount = this.gross_amount - this.retention_amount;        
      })
      .catch((e) => {
        console.log(e);
      });
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant");
  }
  mounted() {
          let current = new Date();
      let start_date = `${current.getFullYear()}-${current.getMonth()}-${current.getDate()}`;
      this.selected_report_date_from = start_date;

      let end_date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()+1}`;
      this.selected_report_date_to = end_date;
      
    let obj = {
        start_date : this.selected_report_date_from,
        end_date : this.selected_report_date_to,
        project : 'All',
      }
     this.callservice(obj)
  }

  get_pdf_row(getdata:any){
    console.log(getdata)
    let routeData:any;
    if(getdata.ref_invoice_id){
      if(getdata.project == 'Aramco-Jeddah'){
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-jeddah-credit/${getdata.id}` });
      }
      if(getdata.project == 'Aramco-Riyadh'){
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-riyadh-credit/${getdata.id}` });
      }
      if(getdata.project == 'Tanajeeb'){
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-tanajeeb-credit/${getdata.id}` });
      }
      if(getdata.project == 'Milling-company'){
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${getdata.id}` });
      }
      if(getdata.project == 'Mastoura'){
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-aramco-mastoura-credit/${getdata.id}` });
      }
     }else{
      if(getdata.project == 'Milling-company'){
        routeData = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${getdata.id}` });
      }else{
       routeData = this.$router.resolve({ path: `/invoice/sales-credit-jeddah-general/${getdata.id}` });
      }
     }
     
    window.open(routeData.href, '_blank');     

  }

  print(){
     let route:any =  null;
     if(this.sales.ref_invoice_id){
      if(this.sales.project == 'Aramco-Jeddah'){
        route = this.$router.resolve({ path: `/invoice/sales-invoice-jeddah-credit/${this.sales.id}` });
      }
      if(this.sales.project == 'Aramco-Riyadh'){
        route = this.$router.resolve({ path: `/invoice/sales-invoice-riyadh-credit/${this.sales.id}` });
      }
       if(this.sales.project == 'Tanajeeb'){
        route = this.$router.resolve({ path: `/invoice/sales-invoice-tanajeeb-credit/${this.sales.id}` });
      }
       if(this.sales.project == 'Milling-company'){
        route = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${this.sales.id}` });
      }
      if(this.sales.project == 'Mastoura'){
        route = this.$router.resolve({ path: `/invoice/sales-invoice-aramco-mastoura-credit/${this.sales.id}` });
      }
      //  route = this.$router.resolve({ path: `/invoice/sales-invoice-jeddah-credit/${this.sales.id}` });
     }else{
      if(this.sales.project == 'Milling-company'){
        route = this.$router.resolve({ path: `/invoice/sales-invoice-milling-credit/${this.sales.id}` });
      }else{
        route = this.$router.resolve({ path: `/invoice/sales-credit-jeddah-general/${this.sales.id}` });
      }
       
     }
      
    window.open(route.href);
  }
}
